import { defineStore } from "pinia";
import axios from "@/utils/axios";
import { computed, ref } from "vue";
import { type Ambience } from "@/types/Ambience";

export const useAmbienceStore = defineStore("ambience", () => {
    const context_id = ref<string | null>(null);
    const ambience = ref<Ambience | null>(null);
    const is_recording = ref(false);
    const ambience_id = computed(() => ambience.value?.id);
    const model = computed(() => ambience.value?.model);


    const setup = async (c_id: string) => {
        context_id.value = c_id;
        await create_ambient(context_id.value!);
    }

    const create_ambient = async (context_id: string) => {
        const url = `/api/v2/contexts/${context_id}/ambients`;
        const response = await axios.post(url);
        ambience.value = response.data;
    }


    return {
        setup,
        ambience_id,
        model,
        is_recording
    }
});