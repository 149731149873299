import { useMeStore } from '@/stores/me'
import { createRouter, createWebHistory } from 'vue-router'


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('../views/CopilotContext/Index.vue')
        },
        {
            path: '/context/:context_id',
            component: () => import('../views/CopilotContext/CopilotContext.vue'),
            children: [
                {
                    path: "",
                    name: "context",
                    component: () => import('../views/CopilotContext/CopilotThreadList.vue')
                }
            ]
        },
        {
            path: '/history',
            name: 'history',
            component: () => import('../views/History/History.vue'),
        },
        {
            path: '/login',
            name: 'login',
            components: {
                default: () => import('../views/CopilotContext/CopilotContext.vue'),
                modal: () => import('../views/Login.vue')
            }
        },
        {
            path: '/profile',
            name: 'profile',
            components: {
                modal: () => import('../modals/Settings.vue'),
            },
            children: [
                {
                    path: "",
                    name: "profile.settings",
                    component: () => import('../views/Settings/Profile.vue')
                },
                {
                    path: 'billing',
                    name: 'profile.billing',
                    component: () => import('../views/Settings/Billing.vue')
                },
            ]
        },
        {
            path: '/me/update',
            name: 'update_me',
            component: () => import('../components/UpdateMyInfo.vue')
        },
        {
            path: "/admin",
            component: () => import('../views/Admin/Admin.vue'),
            children: [
                {
                    path: "assistants",
                    name: "assistants",
                    component: () => import('../views/Admin/Assistants.vue'),
                },
                {
                    name: "customers",
                    path: "customers",
                    component: () => import('../views/Admin/Customers.vue')
                },
                {
                    name: "personas",
                    path: "personas",
                    component: () => import('../views/Admin/Personas.vue')
                },


                {
                    path: "new",
                    name: "new-assistant",
                    component: () => import('../views/Admin/Assistants/CreateAssistant.vue')
                },
                {
                    path: "edit/:id",
                    name: "edit-assistant",
                    component: () => import('../views/Admin/Assistants/EditAssistant.vue'),
                    props: true
                },
                {
                    name: "analytics",
                    path: "analytics",
                    component: () => import('../views/Admin/Analytics.vue')
                }
            ]
        }

    ]
})


// for some godforsaken reason, the user from the store is not available in the beforeEach hook
// so we have to load it again
// that is some added latency that we could do without
// but it is what it is
router.beforeEach(async (to, from, next) => {
    const me_store = useMeStore();
    let me = me_store.me;
    let email = me?.email
    if (to.name !== 'login' && to.name != "update_me" && !email) {
        if (!me) {
            await me_store.fetch_me();
            me = me_store.me;
            email = me?.email;
        }

        if (!email) {
            next({ name: 'update_me' });
        }
        else {
            next();
        }

    } else {
        next()
    }
})

export default router;