import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import axios, { AxiosError } from "axios";
import { useMeStore } from "./me";
import { type Thread, type Context, type Message, type ChatFile } from "@/types/Context";
import type { Knowledgebasehit } from "@/types/Search";
import { useAmplitudeStore } from "./amplitude";
import { DateTime } from "luxon";
import { useMyContextsStore } from "./contexts";
import type { AgentExecutionResult } from "@/types/Agents";
import { useAmbienceStore } from "./ambience";
import { read_sse } from "@/utils/sse";



export const useContextStore = defineStore("context_store", () => {
    const contexts_stores = useMyContextsStore();
    const { contexts } = storeToRefs(contexts_stores);
    const context = ref<Context | null>(null);
    const current_thread = ref<Thread | null>(null);
    const amplitude = useAmplitudeStore();
    const me_store = useMeStore();
    const { me } = storeToRefs(me_store);
    const asking = ref(false);
    const ambience_store = useAmbienceStore();
    const { is_recording } = storeToRefs(ambience_store);
    const controller = ref<AbortController | null>(null);




    const create_context = async () => {
        if (controller.value) {
            controller.value.abort();
        }

        asking.value = false;
        controller.value = new AbortController();
        const workspace_id = me.value?.default_workspace_id;
        const response = await axios.post("/api/v1/workspaces/" + workspace_id + "/contexts", {
        })
        context.value = response.data;
        context.value!.threads = [];
        amplitude.track("Context Created", {
            context_id: context.value?.id
        })
        if (contexts.value === null) {
            contexts.value = [];
        }

        contexts.value.unshift(context.value!);
    }

    const create_thread = async () => {
        const response = await axios.post("/api/v1/contexts/" + context.value?.id + "/threads", {
        })
        const thread = response.data;
        thread.messages = [];
        context.value?.threads.push(thread);
        amplitude.track("Thread Created", {
            context_id: context.value?.id,
            thread_id: thread.id
        })
    }
    const send_message = async (message: string, attachments: ChatFile[]) => {
        controller.value = new AbortController();
        asking.value = true;


        try {
            if (context.value === null) {
                await create_context();
                amplitude.track("First Message Sent", {
                    context_id: context.value!.id,
                    thread_id: context.value!.current_thread?.id
                });
            }

            if (context.value?.current_thread === null || context.value?.current_thread === undefined) {
                await create_thread();
                context.value!.current_thread = context.value!.threads[0];
                amplitude.track("First Message Sent", {
                    context_id: context.value!.id,
                    thread_id: context.value!.current_thread?.id
                });
            }

            else {
                const number_of_messages_in_thread = context.value?.current_thread?.messages.length;
                amplitude.track("Message Sent", {
                    context_id: context.value?.id,
                    thread_id: context.value?.current_thread?.id,
                    message_number: number_of_messages_in_thread
                })
            }



            const now = DateTime.now();
            const now_as_string = now.toISO();
            const current_thread = context.value?.current_thread;

            current_thread?.messages.push({
                id: now_as_string + "ask",
                thread_id: current_thread?.id,
                content: message,
                author: "User",
                metadata: null,
                attachments: attachments,
                created_at: now_as_string
            });

            const reply = ref<Message>({
                id: now_as_string + "reply",
                thread_id: current_thread?.id,
                content: "",
                author: "Assistant",
                metadata: {
                    references: []
                },
                created_at: now_as_string,
            });

            current_thread?.messages.push(reply.value);
            const url = "/api/v1/threads/" + current_thread?.id + "/messages";
            const xsrfToken = document.cookie
                .split('; ')
                .find((cookie) => cookie.startsWith('XSRF-TOKEN'))
                ?.split('=')[1];
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text/event-stream',
                    'X-XSRF-TOKEN': xsrfToken || '',
                },
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                body: JSON.stringify({
                    Ask: message,
                    Attachments: attachments
                })
            })


            const reader = response.body?.getReader();
            if (!reader) {
                return;
            }

            await read_sse(reader, controller.value.signal, (element: { Data: string, Type: string }) => {
                if (element.Data === null) {
                    return
                }

                if (element.Type === "message") {
                    reply.value.content += element.Data;
                }

                if (element.Type == "references") {
                    const then = DateTime.now();
                    const diff = then.diff(now, "seconds").seconds;
                    const parsed: Knowledgebasehit[] = JSON.parse(element.Data);
                    if (parsed.length > 0) {
                        amplitude.track("Reference Received", {
                            context_id: context.value?.id,
                            thread_id: context.value?.current_thread?.id,
                            time_s: diff
                        })
                    }
                    else {
                        amplitude.track("No Reference Received", {
                            context_id: context.value?.id,
                            thread_id: context.value?.current_thread?.id,
                            time_s: diff
                        })
                    }
                    reply.value.metadata!.references = parsed;
                    parsed.forEach(element => {
                        const facts = element.Facts;
                        facts.forEach(fact => {
                            amplitude.track("Reference", {
                                context_id: context.value?.id,
                                thread_id: context.value?.current_thread?.id,
                                name: element.Knowledgebase,
                                document: fact.SourceName
                            })
                        });
                    });
                }

                if (element.Type === "end") {
                    reply.value.id = element.Data;
                }

                if (element.Type == "usage") {
                    const parsed: { total_tokens: number } = JSON.parse(element.Data);
                    reply.value.tokens = parsed.total_tokens
                }
            });

            const then = DateTime.now();
            const diff = then.diff(now, "seconds").seconds;
            const tokens = reply.value.tokens;
            amplitude.track("Message Received", {
                context_id: context.value?.id,
                thread_id: context.value?.current_thread?.id,
                time_s: diff,
                tokens: tokens
            });

            const index = contexts.value.findIndex(c => c.id === context.value?.id);
            if (index !== -1) {
                contexts.value[index] = context.value!;
            }
        }

        catch (error) {
            console.error("Error sending message", error);
            if (error instanceof AxiosError) {
                const status = error.response?.status
                if (status === 401) {
                    me_store.fetch_me();
                }
            }
        }
        finally {
            asking.value = false;
        }
    }


    const load_context = async (context_id: string) => {
        if (controller.value) {
            controller.value.abort();
        }

        controller.value = new AbortController();
        asking.value = false;

        const response = await axios.get("/api/v1/contexts/" + context_id);
        context.value = response.data;

        if (context.value && context.value.threads.length > 0) {
            context.value.current_thread = context.value.threads[0];
            current_thread.value = context.value.current_thread;
        }
    }


    const on_execution_ready = (message: AgentExecutionResult) => {
        if (message.context_id === context.value?.id) {
            const id = message.id;
            const existing = context.value?.agent_executions.find(e => e.id === id);
            if (existing) {
                const index = context.value?.agent_executions.indexOf(existing);
                if (index !== -1) {
                    context.value!.agent_executions[index!] = message;
                }
            }
            else {
                context.value!.agent_executions = [...context.value?.agent_executions!, message];
            }
        }

        const index = contexts.value.findIndex(c => c.id === message.context_id);
        if (index !== -1) {
            const context = contexts.value[index];
            const id = message.id;
            const existing = context.agent_executions.find(e => e.id === id);
            if (existing) {
                const index = context.agent_executions.indexOf(existing);
                if (index !== -1) {
                    context.agent_executions[index!] = message;
                }
            }
            else {
                context.agent_executions.push(message);
            }
        }
    }

    const stop_asking = async () => {
        if (controller.value) {
            controller.value.abort();
        }
        const endpoint = "/api/v1/threads/" + context.value?.current_thread?.id + "/stop";
        await axios.post(endpoint, {});
        asking.value = false;
    }

    const on_context_title_generated = (context_id: string, title: string) => {
        if (context.value && context.value.id === context_id) {
            context.value.name = title;
        }
        const index = contexts.value.findIndex(c => c.id === context_id);
        if (index !== -1) {
            contexts.value[index].name = title;
        }

    }



    const $reset = () => {
        context.value = null;
        current_thread.value = null;
    }

    const update = async (input: { name: string }) => {
        input.name = input.name.trim();
        if (input.name === "") {
            return;
        }
        context.value!.name = input.name;
        await axios.put("/api/v1/contexts/" + context.value!.id, {
            name: input.name
        });
        amplitude.track("Context Name Updated", {
            context_id: context.value?.id
        });


        const index = contexts.value.findIndex(c => c.id === context.value?.id);
        if (index !== -1) {
            contexts.value[index] = context.value!;
        }
    }

    return {
        asking,
        context,
        current_thread,
        ask: send_message,
        stop_asking,
        load_context,
        create_context,
        update,
        on_execution_ready,
        is_recording,
        on_context_title_generated,
        $reset
    }
});



